.header-1 {
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        bottom: 0;
        background: linear-gradient(rgba($primary, 0.07) 0%, rgba($primary, 0.05) 100%) no-repeat left center;
        background-size: calc(1240px + (100vw - 1240px)/ 2) 100%;
        transform: skewY(-6deg);
        transform-origin: top left;
    }

    @media (max-width: 768px) {
        &::before {
            background-size: 100% 100%;
        }
    }
}

.header-2 {
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        bottom: 0;
        background: linear-gradient(rgb(255, 255, 255) 0%, rgba($warning, 0.05) 100%) no-repeat left center;
        background-size: calc(1240px + (100vw - 1240px)/ 2) 100%;
        transform: skewY(3deg);
        transform-origin: top left;
    }

    &.primary {
        padding-bottom: 5rem;
        &::before {
            top: -6rem;
            background: linear-gradient($gray-100 0%, $gray-50 100%) no-repeat left center;
            background-size: calc(1240px + (100vw - 1240px)/ 2) 100%;
        }
    }

    @media (max-width: 768px) {
        &::before {
            background-size: 100% 100%;
        }
    }
}


.header-3 {
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        bottom: 0;
        background: linear-gradient(rgba($primary, 0.07) 0%, rgba($primary, 0.05) 100%) no-repeat right center;
        // background-size: calc(1240px + (100vw - 1240px)/ 2) 100%;
    }

    @media (max-width: 768px) {
        &::before {
            background-size: 100% 100%;
        }
    }
}

.header-4 {
    position: relative;

    &::before {
        content: "";
        width: calc(100% - 60px);
        height: 100%;
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        bottom: 0;
        background: linear-gradient(rgba($warning, 0.05) 0%, rgba($warning, 0.07) 100%) no-repeat left center;
    }
}

.header-5 {
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        bottom: 0;
        background: linear-gradient(rgba($orange, 0.05) 0%, rgba($orange, 0.07) 100%) no-repeat left center;
    }

    @media (max-width: 768px) {
        &::before {
            background-size: 100% 100%;
        }
    }
}

.header-6 {
    position: relative;

    &::before {
        content: "";
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        bottom: 0;
        background: linear-gradient(rgba($orange, 0.075) 0%, rgba($warning, 0.1) 100%) no-repeat left center;
    }
}

.header-7 {
    position: relative;

    &::before {
        content: "";
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        bottom: 0;
        background: linear-gradient(rgba($gray-100, 0.25) 0%, rgba($gray-100, 0.4) 100%) no-repeat right center;
    }

    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba($gray-900, 0.25);
    }
}

.main-header {
    .top-bar {
        background: var(--bs-light);
        padding: 8px 0;
        font-size: 14px;
        border-bottom: 1px solid rgba(0,0,0,0.05);

        a {
            color: var(--bs-gray-600);
            text-decoration: none;
            transition: color 0.2s ease;
            display: inline-flex;
            align-items: center;

            &:hover {
                color: var(--bs-primary);
            }

            .feather {
                width: 16px;
                height: 16px;
            }
        }

        .social-links {
            a {
                width: 28px;
                height: 28px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: var(--bs-white);
                
                &:hover {
                    background: var(--bs-primary);
                    color: var(--bs-white);
                    transform: translateY(-2px);
                }
            }
        }
    }

    .navbar {
        padding: 15px 0;
        background: var(--bs-white);
        box-shadow: 0 2px 4px rgba(0,0,0,0.02);

        &.navbar-sticky {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1030;
            animation: slideDown 0.35s ease-out;
        }

        .brand-wrapper {
            .brand-logo {
                transition: transform 0.3s ease;
                
                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        .navbar-toggler {
            border: none;
            padding: 0;
            
            &:focus {
                box-shadow: none;
            }

            .feather {
                width: 28px;
                height: 28px;
                color: var(--bs-dark);
            }
        }

        .nav-link {
            color: var(--bs-gray-700);
            font-weight: 500;
            padding: 8px 16px;
            transition: all 0.3s ease;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 0;
                height: 2px;
                background: var(--bs-primary);
                transition: all 0.3s ease;
                transform: translateX(-50%);
            }

            &:hover, &.active {
                color: var(--bs-primary);

                &:after {
                    width: 20px;
                }
            }
        }

        .nav-actions {
            .btn {
                padding: 8px 20px;
                font-weight: 500;
                display: inline-flex;
                align-items: center;
                
                .feather {
                    width: 16px;
                    height: 16px;
                }
            }

            .btn-soft-primary {
                background: rgba(var(--bs-primary-rgb), 0.1);
                color: var(--bs-primary);
                border: none;

                &:hover {
                    background: var(--bs-primary);
                    color: var(--bs-white);
                }
            }
        }
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}