.policy-wrapper {
    background-color: #f8f9fa;

    .policy-nav {
        display: flex;
        justify-content: center;
        gap: 2rem;
        padding: 1rem;
        background: white;
        border-radius: 1rem;
        box-shadow: 0 4px 12px rgba(0,0,0,0.05);

        .policy-nav-item {
            display: flex;
            align-items: center;
            color: var(--bs-gray-600);
            text-decoration: none;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            transition: all 0.3s ease;

            &:hover {
                color: var(--bs-primary);
                background: rgba(var(--bs-primary-rgb), 0.1);
            }

            &.active {
                color: var(--bs-primary);
                background: rgba(var(--bs-primary-rgb), 0.1);
            }

            .feather {
                width: 18px;
                height: 18px;
            }
        }
    }

    .policy-card {
        background: white;
        border-radius: 12px;
        box-shadow: 0 2px 12px rgba(0,0,0,0.03);
        overflow: hidden;

        .policy-header {
            padding: 2rem;
            text-align: center;
            background: linear-gradient(45deg, #f8f9fa, #e9ecef);
            color: #212529;

            .header-icon {
                width: 48px;
                height: 48px;
                margin-bottom: 1rem;
                color: #0d6efd;
            }

            p {
                color: #6c757d;
            }
        }

        .policy-content {
            padding: 2rem;
        }
    }

    .feature-list {
        list-style: none;
        padding: 0;
        
        li {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            color: #495057;

            .text-success {
                color: #198754 !important;
            }
        }
    }

    .security-card {
        padding: 1.5rem;
        text-align: center;
        background: #f8f9fa;
        border-radius: 8px;
        height: 100%;
        border: 1px solid #e9ecef;

        .feather {
            width: 32px;
            height: 32px;
            color: #0d6efd;
        }

        h4 {
            color: #212529;
            margin: 1rem 0 0.5rem;
        }

        p {
            color: #6c757d;
            margin: 0;
        }
    }

    .timeline {
        position: relative;
        padding: 2rem 0;

        &:before {
            content: '';
            position: absolute;
            left: 32px;
            top: 0;
            bottom: 0;
            width: 2px;
            background: #e9ecef;
        }

        .timeline-item {
            display: flex;
            margin-bottom: 2rem;
            position: relative;
        }

        .timeline-icon {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.5rem;
            border: 2px solid #e9ecef;
            z-index: 1;
            box-shadow: 0 2px 8px rgba(0,0,0,0.05);

            .feather {
                color: #0d6efd;
            }
        }

        .timeline-content {
            flex: 1;

            h4 {
                color: #212529;
                margin-bottom: 0.5rem;
            }

            p {
                color: #6c757d;
                margin: 0;
            }
        }
    }

    .responsibility-card {
        background: #fff;
        padding: 1.5rem;
        border-radius: 8px;
        border: 1px solid #e9ecef;
        border-left: 4px solid #ffc107;

        .feather {
            width: 32px;
            height: 32px;
            color: #ffc107;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            color: #495057;

            li {
                margin-bottom: 0.5rem;
                padding-left: 1.5rem;
                position: relative;

                &:before {
                    content: "•";
                    color: #ffc107;
                    position: absolute;
                    left: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    // Section titles
    h1, h2, h3, h4 {
        color: #212529;
    }

    .text-muted {
        color: #6c757d !important;
    }

    .lead {
        color: #495057;
    }
}

.company-info {
    border-left: 4px solid var(--bs-primary);
    
    ul {
        margin-top: 1rem;
        
        li {
            margin-bottom: 0.5rem;
            
            strong {
                display: inline-block;
                min-width: 150px;
                color: var(--bs-gray-700);
            }
        }
    }
}

// Add smooth scrolling
html {
    scroll-behavior: smooth;
} 