
.benefit-card {
    background: white;
    border-radius: 1rem;
    transition: all 0.3s ease;
    height: 100%;
    border: 1px solid var(--bs-gray-200);

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0,0,0,0.05);
    }

    .benefit-icon {
        width: 64px;
        height: 64px;
        background: rgba(var(--bs-primary-rgb), 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        .feather {
            width: 32px;
            height: 32px;
            color: var(--bs-primary);
        }
    }
}

.problem-list, .solution-list {
    .feather {
        width: 24px;
        height: 24px;
    }
} 