
.filter-menu {
    .filter-menu-item {
        display: inline-block;
        font-family: $btn-font-family;
        font-weight: $btn-font-weight;
        color: $body-color;
        text-align: center;
        text-decoration: if($link-decoration==none, null, none);
        white-space: $btn-white-space;
        vertical-align: middle;
        user-select: none;
        background-color: transparent;
        border: $btn-border-width solid transparent;
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
        line-height: $line-height-base;
        border-radius: $border-radius-sm;
        font-size: $input-btn-font-size-sm;
        margin: 4px 2px;
        // @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $input-btn-font-size-sm, $line-height-sm, $btn-border-radius-sm);

        border-color: $gray-300;
        transition: $btn-transition;

        &.active {
            color: $white !important;
            background-color: $primary !important;
            border-color: $primary !important;
        }

        &:focus,
        &.focus {
            outline: 0;
            box-shadow: $btn-focus-box-shadow;
        }

        // Disabled comes first so active can properly restyle
        &.disabled,
        &:disabled {
            opacity: $btn-disabled-opacity;
            @include box-shadow(none);
        }

        &:not(:disabled):not(.disabled) {
            // cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

            &:active,
            &.active {
                @include box-shadow($btn-active-box-shadow);

                &:focus {
                    @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
                }
            }
        }

        &:hover,
        &:focus {
            background-color: $white;
            box-shadow: 0 1px 3px rgba(60, 72, 88, 0.15), 0 2px 4px 2px rgba(60, 72, 88, 0.18);
        }
    }
}

.masonry-grid {
    position: relative;
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: height 0.3s ease-out, opacity 0.3s ease-out 0.3s;
    [data-masonry-el] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.3s ease-in-out;
    }    
}
