.chatbot-container {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 1000;

    .chatbot-toggle {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: var(--bs-primary);
        color: white;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 12px rgba(var(--bs-primary-rgb), 0.3);
        transition: all 0.3s ease;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 6px 16px rgba(var(--bs-primary-rgb), 0.4);
        }

        .feather {
            width: 24px;
            height: 24px;
        }
    }

    .chatbot-window {
        position: absolute;
        bottom: 80px;
        right: 0;
        width: 350px;
        height: 500px;
        background: white;
        border-radius: 1rem;
        box-shadow: 0 10px 30px rgba(0,0,0,0.1);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        animation: slideUp 0.3s ease;

        .chatbot-header {
            padding: 1rem;
            background: var(--bs-primary);
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .close-btn {
                background: none;
                border: none;
                color: white;
                cursor: pointer;
                padding: 0;

                .feather {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .chatbot-messages {
            flex: 1;
            padding: 1rem;
            overflow-y: auto;

            .message {
                display: flex;
                align-items: flex-start;
                margin-bottom: 1rem;

                &.user {
                    flex-direction: row-reverse;

                    .message-content {
                        background: var(--bs-primary);
                        color: white;
                        border-radius: 1rem 1rem 0 1rem;
                        margin-left: 0;
                    }
                }

                &.bot {
                    .message-content {
                        background: var(--bs-light);
                        border-radius: 1rem 1rem 1rem 0;
                    }
                }

                .bot-avatar {
                    width: 32px;
                    height: 32px;
                    background: var(--bs-primary);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 0.5rem;

                    .feather {
                        width: 16px;
                        height: 16px;
                        color: white;
                    }
                }

                .message-content {
                    padding: 0.75rem 1rem;
                    max-width: 80%;
                }
            }
        }

        .chatbot-input {
            padding: 1rem;
            border-top: 1px solid var(--bs-gray-200);
            display: flex;
            gap: 0.5rem;

            input {
                flex: 1;
                padding: 0.5rem 1rem;
                border: 1px solid var(--bs-gray-200);
                border-radius: 2rem;
                outline: none;

                &:focus {
                    border-color: var(--bs-primary);
                }
            }

            button {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: var(--bs-primary);
                color: white;
                border: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;

                &:hover {
                    transform: translateY(-2px);
                }

                .feather {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
} 