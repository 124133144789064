
.pricing-section {
    .bg-soft-primary {
        background: rgba(var(--bs-primary-rgb), 0.1);
        color: var(--bs-primary);
    }

    .pricing-card {
        background: white;
        border-radius: 1rem;
        padding: 2rem;
        height: 100%;
        transition: all 0.3s ease;
        border: 1px solid var(--bs-gray-200);
        position: relative;
        overflow: hidden;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 30px rgba(0,0,0,0.08);
        }

        &.premium {
            border: 2px solid var(--bs-primary);
            background: linear-gradient(white, rgba(var(--bs-primary-rgb), 0.02));

            .pricing-header {
                h3 {
                    color: var(--bs-primary);
                }
            }
        }

        .popular-badge {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background: var(--bs-warning);
            color: var(--bs-dark);
            padding: 0.5rem 1rem;
            border-radius: 2rem;
            font-size: 0.875rem;
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        .pricing-header {
            text-align: center;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid var(--bs-gray-200);

            .pricing-value {
                margin: 1.5rem 0;

                h1 {
                    font-weight: 600;
                    
                    span {
                        color: var(--bs-gray-600);
                        font-weight: normal;
                    }
                }
            }
        }

        .pricing-features {
            .feature-item {
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
                color: var(--bs-gray-700);

                .feather {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
} 