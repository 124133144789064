
.about-section {
    .mission-section {
        border-left: 4px solid var(--bs-primary);
    }

    .value-card {
        background: white;
        border-radius: 1rem;
        height: 100%;
        transition: all 0.3s ease;
        border: 1px solid var(--bs-gray-200);

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 20px rgba(0,0,0,0.05);
        }

        .value-icon {
            width: 60px;
            height: 60px;
            background: rgba(var(--bs-primary-rgb), 0.1);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            .feather {
                width: 30px;
                height: 30px;
            }
        }
    }

    .cta-section {
        background: linear-gradient(45deg, var(--bs-light), white);
        border: 1px solid var(--bs-gray-200);
    }

    ul li {
        position: relative;
        padding-left: 0.5rem;
    }
} 